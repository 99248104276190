@use '@angular/material' as mat;
@use './styles/theme.scss' as *;
@use './styles/variables.scss' as *;
@use './styles/functions.scss' as *;
@use './styles/classes.scss' as *;

@use 'sass:math';

@page {
  size: A4;
}

$apex-green: $AG-300;

$default-padding: 16px;
$default-padding-small: math.div($default-padding, 2);
$default-padding-large: $default-padding * 2;
$default-padding-extra-small: math.div($default-padding-small, 2);

@media print {
  html {
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    font-family: 'Open Sans', calibri;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;

    margin: 0rem;
    padding: 0rem;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Titillium Web', Verdana;
      font-size: 15px;
      line-height: 1.3;
      font-weight: 300;
    }

    h1 {
      font-size: 24px;
      font-weight: 200;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 16px;
    }
  }

  a {
    color: $AT-700;
  }

  .divider-top {
    border-top: solid 1px rgba(0, 0, 0, 0.12);
  }

  .divider-bottom {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  }

  .divider-left {
    border-left: solid 1px rgba(0, 0, 0, 0.12);
  }

  .divider-right {
    border-right: solid 1px rgba(0, 0, 0, 0.12);
  }

  .case-complete,
  .complete,
  .canceled,
  .error,
  .new {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 5px;
      top: 0;
      left: 0;
      background-color: $apex-green;
      z-index: 1;
    }

    &:before {
      transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
      width: 100%;
      opacity: 0;
      transform-origin: 0 0;
    }

    &:hover {
      &:before {
        opacity: 0.1;
        animation-name: scale-on-hover;
        animation-duration: 0.6s;
      }
    }

    > * {
      z-index: 1;
    }
  }

  @keyframes scale-on-hover {
    from {
      transform: scaleX(0);
    }

    to {
      transform: scaleX(1);
    }
  }

  .bg {
    &.error {
      background-color: scale-color($AR-500, $lightness: 90%);
    }
  }

  .canceled {
    &:before,
    &:after {
      background-color: $AR-300;
    }
  }

  .error {
    &:before,
    &:after {
      background-color: $AR-500;
    }
  }

  .new {
    &:before,
    &:after {
      background-color: $AK-300;
    }

    &:before {
      left: 0;
    }

    &:after {
      display: none;
    }
  }

  .cases {
    $title-height: $default-padding * 2;
    $title-margin: $default-padding;
    $bottom-margin: $default-padding;
    $top-margin: $default-padding;

    $cards-count-on-page: 5;

    $card-height-first-page: calc(
      (100vh - #{$top-margin} - #{$title-height} - #{$title-margin} - #{$bottom-margin}) / #{$cards-count-on-page}
    );
    $card-height: calc((100vh - #{$top-margin} - #{$bottom-margin}) / #{$cards-count-on-page});
    $card-height-new-page: calc(#{$card-height} + #{$top-margin});

    .title {
      height: $title-height;
      margin-bottom: 0px;
    }

    .case {
      height: $card-height-first-page;
      page-break-inside: avoid;

      overflow: hidden;

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      p,
      h1,
      h2,
      h3 {
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .card {
        @include mat.elevation(2);
        /*border-left: .5vh solid #fff;*/
        max-width: calc(100% - 4px);
        display: flex;
        flex: 1;

        &.completed {
          border-left: 0.5vh solid #3bb4a1;
        }
      }

      .info {
        padding: 16px;
        display: flex;
        flex-direction: column;
        flex: calc(100% - #{$card-height-first-page});
        max-width: calc(100% - #{$card-height-first-page});

        .header {
          h1 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .main {
          display: flex;
          flex: 1;

          .details {
            display: flex;
            flex: 60%;
            max-width: 60%;
            flex-direction: column;
            justify-content: space-between;

            .description {
              padding-right: $default-padding;
              flex: 1;

              p {
                display: -webkit-box;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                overflow: hidden;
                white-space: normal;
              }
            }
          }

          .humans {
            display: flex;
            flex: 40%;
            max-width: 40%;
            justify-content: flex-end;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }

      .files {
        display: flex;
        flex-wrap: wrap;
        flex: $card-height-first-page;

        &.s1 {
          .file {
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
          }
        }

        &.s2 {
          .file {
            width: 50%;
            height: 100%;
            min-width: 50%;
            min-height: 100%;
          }
        }

        &.s3,
        &.s4 {
          .file {
            width: 50%;
            height: 50%;
            min-width: 50%;
            min-height: 50%;
          }
        }

        .file {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }

      p > strong {
        margin-right: 0.25rem;
      }
    }

    .case:nth-child(2) {
      padding-top: $default-padding;
      height: calc(#{$card-height-first-page} + #{$default-padding});

      &.case:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height-first-page} + #{$default-padding * 3});
      }
    }

    .case:nth-child(n + #{$cards-count-on-page + 2}) {
      height: $card-height;

      .info {
        flex: calc(100% - #{$card-height});
        max-width: calc(100% - #{$card-height});
      }

      .files {
        flex: $card-height;
      }
    }

    // Last of every page
    .case:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 1}) {
      height: calc(#{$card-height-first-page});

      &.case:nth-child(n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height});
      }

      .card {
        margin-bottom: 2px;
      }
    }

    .case:nth-last-child(1) {
      height: calc(#{$card-height-first-page} + 16px);

      &.case:nth-child(n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height} + 16px);
      }

      .card {
        margin-bottom: $default-padding;
      }

      &.case:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 1}) {
        height: calc(#{$card-height-first-page});

        &.case:nth-child(n + #{$cards-count-on-page + 2}) {
          height: calc(#{$card-height});
        }

        .card {
          margin-bottom: 2px;
        }
      }
    }

    // Every new page
    .case:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
      padding-top: $top-margin;
      height: $card-height-new-page;
    }
  }

  .break-after {
    page-break-after: always;
  }

  .break-before {
    page-break-before: always;
  }

  .break-inside-avoid {
    page-break-inside: avoid;
  }

  .inspection {
    table.involved {
      width: 100%;
      border-collapse: collapse;
      display: table;

      .l {
        text-align: left;
      }

      .c {
        text-align: center;
      }

      .icon {
        margin: auto;
      }

      th {
        color: rgba(0, 0, 0, 0.54);
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
        padding: $default-padding-small;
        font-size: 12px;
        font-weight: 500;
      }

      td {
        padding: $default-padding-small;
      }
    }

    .signatures {
      $title-height: $default-padding * 2;
      $title-margin: $default-padding;
      $bottom-margin: $default-padding;
      $top-margin: $default-padding;

      $cards-count-on-page: 5;

      $card-height-first-page: calc(
        (100vh - #{$title-height} - #{$title-margin * 2} - #{$bottom-margin}) / #{$cards-count-on-page}
      );
      $card-height: calc((100vh - #{$top-margin} - #{$bottom-margin}) / #{$cards-count-on-page});
      $card-height-new-page: calc(#{$card-height} + #{$top-margin});

      h1 {
        margin-bottom: 0px;
      }

      .signature {
        height: $card-height-first-page;
        display: flex;
        overflow: hidden;

        .card {
          margin-right: 2px;
          margin-left: 2px;
          flex: 1;
          display: flex;
          flex-direction: column;

          img {
            max-width: 100%;
            margin: auto;
            flex: 1;
            min-height: 0;
            object-fit: contain;
          }

          p {
            width: 100%;
            text-align: center;
          }
        }
      }

      .signature:nth-child(2) {
        padding-top: $default-padding;
        height: calc(#{$card-height-first-page} + #{$default-padding});

        &.signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
          height: calc(#{$card-height-first-page} + #{$default-padding * 3});
        }
      }

      .signature:nth-child(n + #{$cards-count-on-page + 2}) {
        height: $card-height;
      }

      .signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 1}) {
        height: calc(#{$card-height-first-page} + 16px);

        &.signature:nth-child(n + #{$cards-count-on-page + 2}) {
          height: calc(#{$card-height} + 16px);
        }

        .card {
          margin-bottom: $default-padding;
        }
      }

      .signature:nth-last-child(1) {
        height: calc(#{$card-height-first-page} + 16px);

        &.signature:nth-child(n + #{$cards-count-on-page + 2}) {
          height: calc(#{$card-height} + 16px);
        }

        &.signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
          height: calc(#{$card-height} + #{$top-margin} + #{$default-padding});
        }

        .card {
          margin-bottom: $default-padding;
        }
      }

      .signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height} + #{$top-margin} + #{$default-padding});

        .card {
          margin-top: $default-padding;
        }
      }
    }

    .images {
      .image {
        height: 50vh;
        display: flex;

        .card {
          flex: 1;
          display: flex;
          flex-direction: column;

          img {
            margin: auto;
            flex: 1;
            max-width: 100%;
            min-height: 0;
            object-fit: contain;
          }

          p {
            width: 100%;
          }
        }
      }

      .image:nth-child(odd) {
        .card {
          margin-bottom: $default-padding;
        }
      }

      .image:nth-child(even) {
        .card {
          margin-top: $default-padding;
        }
      }
    }
  }

  .frontpage {
    display: flex;
    height: 100vh;
    width: 100vw;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    color: $AK-900;
    background-color: $AK-50;

    .top,
    .bottom {
      display: flex;
      flex-direction: column;
    }

    .top {
      flex: 1;
    }

    .bottom {
      flex: 3;
    }

    aside,
    main {
      header {
        flex: 1;
      }

      main {
        flex: 3;
      }

      footer {
        flex: 0.5;
      }
    }

    aside {
      display: flex;
      flex-direction: column;
      flex: 1;

      .top {
        justify-content: center;
        align-items: center;
        background-color: $AK-900;
        color: $AK-50;

        .material-icons {
          $icon-size: 80px;
          font-size: $icon-size;
          height: $icon-size;
          width: $icon-size;
        }
      }

      .bottom {
        justify-content: flex-end;
        background-color: $AK-800;
        color: $AK-50;

        main {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin: $default-padding * 2;
        }

        .mat-caption {
          color: $AK-100;
        }

        a {
          color: currentColor;
          text-decoration: underline;
        }
      }
    }

    main {
      display: flex;
      flex-direction: column;
      flex: 2;

      .top {
        justify-content: center;
        display: flex;
        align-self: flex-start;

        img {
          margin-left: $default-padding * 4;
          background-size: contain;

          max-width: 50vw;
          max-height: 100px;
        }
      }

      .bottom {
        header {
          h1,
          h2 {
            margin: 0;
            margin-bottom: 0.3em;
            font-weight: 200;
            line-height: 1.2em;
            margin-left: $default-padding * 4;
          }

          h1 {
            font-size: 56px;
            letter-spacing: -0.02em;
          }

          h2 {
            font-size: 45px;
            letter-spacing: -0.005em;
          }
        }

        footer {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }
  }

  .involved {
    width: 100%;
    border-collapse: collapse;
    display: flex;

    table {
      flex: 1;

      .l {
        text-align: left;
      }

      .c {
        text-align: center;
      }

      .icon {
        margin: auto;
      }

      th {
        color: rgba(0, 0, 0, 0.54);
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
        padding: $default-padding-small;
        font-size: 12px;
        font-weight: 500;
      }

      td {
        padding: $default-padding-small;
      }
    }
  }

  .signatures {
    $title-height: $default-padding * 2;
    $title-margin: $default-padding;
    $bottom-margin: $default-padding;
    $top-margin: $default-padding;

    $cards-count-on-page: 5;

    $card-height-first-page: calc(
      (100vh - #{$title-height} - #{$title-margin * 2} - #{$bottom-margin}) / #{$cards-count-on-page}
    );
    $card-height: calc((100vh - #{$top-margin} - #{$bottom-margin}) / #{$cards-count-on-page});
    $card-height-new-page: calc(#{$card-height} + #{$top-margin});

    h1 {
      margin-bottom: 0px;
    }

    .signature {
      height: $card-height-first-page;
      display: flex;
      overflow: hidden;

      .card {
        margin-right: 2px;
        margin-left: 2px;
        flex: 1;
        display: flex;
        flex-direction: column;

        img {
          max-width: 100%;
          margin: auto;
          flex: 1;
          min-height: 0;
          object-fit: contain;
        }

        p {
          width: 100%;
          text-align: center;
        }
      }
    }

    .signature:nth-child(2) {
      padding-top: $default-padding;
      height: calc(#{$card-height-first-page} + #{$default-padding});

      &.signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height-first-page} + #{$default-padding * 3});
      }
    }

    .signature:nth-child(n + #{$cards-count-on-page + 2}) {
      height: $card-height;
    }

    .signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 1}) {
      height: calc(#{$card-height-first-page} + 16px);

      &.signature:nth-child(n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height} + 16px);
      }

      .card {
        margin-bottom: $default-padding;
      }
    }

    .signature:nth-last-child(1) {
      height: calc(#{$card-height-first-page} + 16px);

      &.signature:nth-child(n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height} + 16px);
      }

      &.signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
        height: calc(#{$card-height} + #{$default-padding});
      }

      .card {
        margin-bottom: $default-padding;
      }
    }

    .signature:nth-child(#{$cards-count-on-page}n + #{$cards-count-on-page + 2}) {
      height: calc(#{$card-height} + #{$default-padding});

      .card {
        margin-top: $default-padding;
      }
    }
  }

  .meters {
    .object {
      page-break-inside: avoid;
    }

    .list {
      width: 100%;
      display: table;

      .meter {
        display: inline-block;
        width: 25%;
        page-break-inside: avoid;

        .key {
          width: 35px;
        }
      }
    }
  }

  .power-supplier {
    img {
      height: 64px;
      overflow: hidden;
      object-fit: contain;
    }
  }

  .checklist-pug {
    .checklist {
      .item,
      .item-cases-wrapper {
        break-inside: avoid;
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);

        &:last-child {
          border-bottom: none;
        }

        &:not(.item-cases-wrapper) {
          .material-icons {
            width: 48px;
            height: 48px;
            padding-top: 4px;
            font-size: 40px;
          }
        }

        &.complete,
        .complete {
          .material-icons {
            color: $apex-main;
          }
        }
      }
    }

    .not-applicable {
      opacity: 0.5;
      text-decoration: line-through;
    }
  }

  .object-list {
    h3 {
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }

    .steps {
      &:not(:last-child) {
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
      }

      .highlighted {
        background-color: $AT-50;
      }
    }
  }

  .attachments {
    .images {
      .image {
        height: 50vh;
        display: flex;

        .card {
          flex: 1;
          display: flex;
          flex-direction: column;

          img {
            margin: auto;
            flex: 1;
            max-width: 100%;
            min-height: 0;
            object-fit: contain;
          }

          p {
            width: 100%;
          }
        }
      }

      .image:nth-child(odd) {
        .card {
          margin-bottom: $default-padding;
        }
      }

      .image:nth-child(even) {
        .card {
          margin-top: $default-padding;
        }
      }
    }
  }

  .consents {
    .consent {
      page-break-inside: avoid;
    }
  }

  .file-usage-viewer {
    $title-height: $default-padding * 2;
    $title-margin: $default-padding;
    $bottom-margin: $default-padding;
    $top-margin: $default-padding;

    $cards-count-on-page: 1;

    $card-height-first-page: calc(
      (100vh - #{$top-margin} - #{$title-height} - #{$title-margin} - #{$bottom-margin}) / #{$cards-count-on-page}
    );
    $card-height: calc((100vh - #{$top-margin} - #{$bottom-margin}) / #{$cards-count-on-page});
    $card-height-new-page: calc(#{$card-height} + #{$top-margin});

    .container {
      height: $card-height-first-page;
      display: flex;

      .card {
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        max-height: 100%;

        .top {
          flex: 1;
          display: flex;
        }

        .file-usage {
          margin: auto;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            flex: 1;
            max-width: 80vw;
            max-height: 80vh;
            position: relative;
            z-index: -1;
          }

          .marking {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            z-index: 1;

            .marking-mark {
              width: 100%;
              height: 100%;
              border-radius: 100%;
              position: absolute;
              box-sizing: border-box;
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              background-color: $AT-700;
            }

            .marking-text {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              background-color: $AT-200;
              color: $dark-foreground;
              position: absolute;
              display: flex;
              padding: 1px;
              align-items: center;
              left: 50%;
              padding-left: $default-padding;
              padding-right: $default-padding-small;
              box-sizing: border-box;
              line-height: 12px;
              font-size: 12px;
            }

            &.case {
              &.other {
                .marking-text {
                  @include others();
                }
              }

              &.completed {
                .marking-mark {
                  background-color: $AG-300;
                }
              }

              &.accepted {
                .marking-mark {
                  background-color: $contractor-color;
                }
              }

              &.declined {
                .marking-mark {
                  background-color: $apex-red;
                }
              }
            }
          }
        }

        p {
          width: 100%;
        }
      }
    }

    .container:nth-child(n + #{$cards-count-on-page + 2}) {
      height: $card-height;
    }

    .container:nth-child(n + 3) {
      margin-top: $top-margin;
    }
  }

  .product-control {
    main {
      dl {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: $default-padding;
      }
    }
  }

  .addon-cart {
    .mat-elevation-z2 {
      -webkit-filter: blur(0);
      filter: blur(0);
    }

    footer {
      position: fixed;
      bottom: 0;
    }

    img {
      max-width: 128px;
      max-height: 128px;
    }
  }

  .print-scroll-visible {
    overflow: visible !important;
  }

  .print-display-none {
    display: none;
  }

  .print-display-block {
    display: block;
  }

  .break-before-page {
    break-before: page;
  }

  .break-before-avoid-region {
    break-before: avoid-region;
  }

  .break-inside-avoid {
    break-inside: avoid;
  }

  .border-collapse-collapse {
    border-collapse: collapse;
  }

  table.apex-pdf-table {
    .icon {
      margin: auto;
    }

    th {
      color: rgba(0, 0, 0, 0.54);
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
      padding: $default-padding-small;
      font-size: 12px;
      font-weight: 500;
    }

    td {
      padding: $default-padding-small;
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }

    tr {
      page-break-inside: avoid;
    }
  }

  .cases-extended {
    .case-extended {
      .file-usage-viewer {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;

        h1 {
          margin-top: 0;
        }
      }

      .checklist-pug {
        break-before: unset;

        h1 {
          margin-right: 0;
          margin-left: 0;
        }

        div.ap {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .file {
        .material-icons {
          $icon-size: 80px;
          font-size: $icon-size;
          height: $icon-size;
          width: $icon-size;
        }
      }
    }
  }

  .remove-p-margin {
    &p,
    p {
      margin: 0;
    }
  }
}
